import '../components/common/ahoy'
import '../components/common/swear_words_checker'

import SiteHeaderResizer from "../components/common/site_header_resizer"

window.SiteHeaderResizer = SiteHeaderResizer

import Vue from 'vue';

import '../components/common/sentry'

import VueRouter from 'vue-router';
import VueTurbolinks from 'vue-turbolinks';
import VueAhoy from '../components/common/vue_ahoy';
import VueI18n from "../components/common/vue_i18n";
import VueDynamicFields from "../components/common/vue_dynamic_fields";
import Paginate from 'vuejs-paginate'
import Treeselect from '@riophae/vue-treeselect'

import '../components/common/vue_component_initializer';

import store from '../components/store'

import VuePaginationComponent from '../components/common/pagination'
import VueClickOutsideDirective from '../components/common/directives/click_outside'

import VueStyleSelect from '../components/common/styleselect'
import FeedbackLink from '../components/common/feedback_link'

import VCalendar from 'v-calendar'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

Vue.use(VCalendar, {
  componentPrefix: 'vc'
})
Vue.component('VueSlider', VueSlider)
Vue.component('paginate', Paginate)
Vue.component('treeselect', Treeselect)
Vue.use(store);

Vue.use(VueAhoy);

Vue.use(VueI18n);
Vue.use(VueDynamicFields)

window.Vue = Vue;
window.Vue.use(VueClickOutsideDirective);
window.Vue.component('pagination', VuePaginationComponent);

window.Vue.use(VueRouter);
window.Vue.use(VueTurbolinks);

window.Vue.component('style-select', VueStyleSelect)


import ApolloClient from 'apollo-boost'

const apolloClient = new ApolloClient({
  uri: '/graphql'
});
import VueApollo from 'vue-apollo'

Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})
window.__apolloProvider = apolloProvider;

window.bus = new Vue();

var components = [
  {selector: "[data-vue-component='feedback-link-component']", component: FeedbackLink}
]

window.Agiki.Common.VueComponentInitializer.registerComponents({components: components})
